









































import { Component, Prop, Vue } from "vue-property-decorator";
import { hideComment, unhideComment, getComment } from "@/api";
import type { Comment } from "@/api";
import store from "@/store";
import "@toast-ui/editor/dist/toastui-editor-viewer.css";
import { Viewer } from "@toast-ui/vue-editor";
import { DateTime } from "luxon";

@Component({
  components: {
    Viewer,
  },
})
export default class TopicContentCard extends Vue {
  @Prop() comment!: Comment;

  get user(): typeof store.state.user {
    return store.state.user;
  }

  get dateString(): string {
    return DateTime.fromISO(this.comment.created_at).toLocaleString(
      DateTime.DATETIME_MED
    );
  }

  async hide(): Promise<void> {
    const data = await hideComment(this.comment.id);
    this.$emit("update:comment", data);
  }

  async unhide(): Promise<void> {
    const data = await unhideComment(this.comment.id);
    this.$emit("update:comment", data);
  }

  async showHidden(): Promise<void> {
    const data = await getComment(this.comment.id, true);
    this.$emit("update:comment", data);
  }

  quote(): void {
    console.log(this.comment.content);
    const content = this.comment.content
      ?.replaceAll("<br>", "")
      .replaceAll("\n", "\n> ");
    const value = `>${this.dateString}에 ${this.comment.author_name} 님이 작성:
>${content}
`;
    store.commit("setEditorOpen", true);
    store.commit("setEditorInitialValue", value);
  }
}

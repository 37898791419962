






















import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { postFile, postComment } from "@/api";
import "@toast-ui/editor/dist/toastui-editor.css";
import { Editor } from "@toast-ui/vue-editor";
import "@toast-ui/editor/dist/i18n/ko-kr";
import store from "@/store";

@Component({
  components: {
    Editor,
  },
})
export default class NewComment extends Vue {
  @Prop() topicId!: number;
  @Prop() refresh!: () => void;

  get user(): typeof store.state.user {
    return store.state.user;
  }

  editorOptions = {
    usageStatistics: false,
    initialEditType: "wysiwyg",
    hideModeSwitch: true,
    language: "ko-KR",
  };
  $refs!: {
    toastuiEditor: Editor;
  };

  toBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve((reader.result as string).split(",")[1]);
      reader.onerror = (error) => reject(error);
    });
  }

  mounted(): void {
    type Callback = (url: string, text?: string) => void;
    this.$refs.toastuiEditor.invoke(
      "addHook",
      "addImageBlobHook",
      async (file: File, callback: Callback): Promise<void> => {
        if (file.size > 10 * 1000000) {
          store.commit(
            "setErrormodal",
            "업로드할 파일 용량은 10MB까지만 가능합니다."
          );
          return;
        } else {
          const { path } = await postFile(file.name, await this.toBase64(file));
          callback(`https://image.librewiki.net/${path}`);
        }
      }
    );
  }

  async handleSubmit(): Promise<void> {
    const markdown = this.$refs.toastuiEditor.invoke("getMarkdown");
    await postComment(this.topicId, markdown);
    localStorage.setItem(
      `topic-last-visited.${this.topicId}`,
      new Date().toISOString()
    );
    this.$refs.toastuiEditor.invoke("reset");
    this.refresh();
    this.modalclose();
  }

  get editorOpen(): boolean {
    return store.state.editor.open;
  }

  @Watch("$store.state.editor.initialValue")
  onInitialValueChange(): void {
    this.$refs.toastuiEditor.invoke(
      "setMarkdown",
      store.state.editor.initialValue
    );
  }

  modalclose(): void {
    store.commit("setEditorOpen", false);
  }
}








































import { Component, Vue, Watch } from "vue-property-decorator";
import InfiniteLoading, { StateChanger } from "vue-infinite-loading";
import {
  getComments,
  getTopic,
  hideTopic,
  unhideTopic,
  suspendTopic,
  unsuspendTopic,
  closeTopic,
  uncloseTopic,
  pinTopic,
  unpinTopic,
} from "@/api";
import type { Topic, Comment, Board } from "@/api";
import TopicCommentCard from "@/components/TopicCommentCard.vue";
import NewComment from "@/components/NewComment.vue";
import store from "@/store";
import { decode } from "html-entities";
import type { MetaInfo } from "vue-meta";

@Component({
  components: {
    TopicCommentCard,
    NewComment,
    InfiniteLoading,
  },
  metaInfo(): MetaInfo {
    const boardName = (this as TopicPage).board.display_name;
    const topicTitle = (this as TopicPage).decodeTitle(
      (this as TopicPage).topic.title
    );
    return {
      title: `${topicTitle} - ${boardName}`,
    };
  },
})
export default class TopicPage extends Vue {
  topic: Topic = {
    id: 0,
    board_id: 0,
    title: "",
    is_closed: false,
    is_suspended: false,
    is_hidden: false,
    is_pinned: false,
    author_id: 0,
    author_name: "",
    comment_count: 0,
    created_at: "",
    updated_at: "",
  };
  comments: Comment[] = [];
  busy = false;
  infiniteId = +new Date();

  get user(): typeof store.state.user {
    return store.state.user;
  }

  get board(): Board {
    return store.state.board;
  }

  decodeTitle(x: string): string {
    return decode(x);
  }

  refresh(): void {
    this.infiniteId += 1;
  }

  async refreshComment(comment: Comment): Promise<void> {
    const index = this.comments.indexOf(comment);
    const newComment = (await getComments(this.topic.id, index, 1))[0];
    this.$set(this.comments, index, newComment);
  }

  async handleInfinite($state: StateChanger): Promise<void> {
    const limit = 20;
    const topicId = parseInt(this.$route.params.topicId);
    const comments = await getComments(topicId, this.comments.length, limit);
    if (comments.length) {
      this.comments.push(...comments);
    }
    if (comments.length < limit) {
      $state.complete();
      setTimeout($state.reset, 10000);
    } else {
      $state.loaded();
    }
  }

  @Watch("$route.params", { immediate: true })
  async fetchData(): Promise<void> {
    this.busy = true;
    try {
      const topicId = parseInt(this.$route.params.topicId);
      const topic = await getTopic(topicId);
      this.topic = topic;
      this.checkCanWrite(topic);
      localStorage.setItem(
        `topic-last-visited.${topic.id}`,
        new Date().toISOString()
      );
    } catch (error) {
      store.commit("setError", error);
    } finally {
      this.busy = false;
    }
  }

  checkCanWrite(tp: Topic): void {
    if (
      tp.is_closed == true ||
      tp.is_suspended == true ||
      tp.is_hidden == true ||
      store.state.user.isBlocked == true
    ) {
      store.commit("setCanWrite", false);
    } else {
      store.commit("setCanWrite", true);
    }
  }

  async hide(): Promise<void> {
    await hideTopic(this.topic.id);
    this.$router.push(`/${this.board.name}`);
  }

  async unhide(): Promise<void> {
    await unhideTopic(this.topic.id);
    await this.fetchData();
  }

  async suspend(): Promise<void> {
    await suspendTopic(this.topic.id);
    await this.fetchData();
  }

  async unsuspend(): Promise<void> {
    await unsuspendTopic(this.topic.id);
    await this.fetchData();
  }

  async close(): Promise<void> {
    await closeTopic(this.topic.id);
    await this.fetchData();
  }

  async unclose(): Promise<void> {
    await uncloseTopic(this.topic.id);
    await this.fetchData();
  }

  async pin(): Promise<void> {
    await pinTopic(this.topic.id);
    await this.fetchData();
  }

  async unpin(): Promise<void> {
    await unpinTopic(this.topic.id);
    await this.fetchData();
  }
}
